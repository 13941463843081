import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import {
  ContentModal,
  StatefulButton,
  SnackbarSimpleError,
} from 'components/shared';
import useBins from 'lib/custom_hooks/useBins';

import styles from '../styles';

const useStyles = makeStyles(styles);

const buttonText = {
  DEFAULT: 'Confirm',
  SUCCESS: 'Success!',
  LOADING: 'Saving...',
  ERROR: 'Error - Try Again',
};

export const UseBinForm = ({ bin }) => {
  const classes = useStyles();
  const [open, toggleOpen] = useState(false);

  const { onUseBin, submitting, submittingError, resetBinsErrors } = useBins();

  const openModal = () => {
    resetBinsErrors();
    toggleOpen(true);
  };

  const closeModal = () => {
    toggleOpen(false);
  };

  return (
    <>
      <Button
        className={classes.useBinButton}
        disabled={bin.inUse}
        onClick={openModal}
        variant="outlined"
      >
        Use Bin
      </Button>

      <ContentModal
        open={open}
        onClose={closeModal}
        data-testid={`use-bin-modal-bin-${bin.id}`}
      >
        <Typography className={classes.formHeader}>Confirm In Use</Typography>
        <Typography className={classes.formContent}>
          Bin {bin.id} - {bin.ingredientName}
        </Typography>
        <div className={classes.formButtons}>
          <Button
            className={classes.cancelButton}
            variant="outlined"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <StatefulButton
            buttonTextOptions={buttonText}
            classes={{ root: classes.confirmButton }}
            failed={submittingError}
            loading={submitting}
            onClick={() => onUseBin(bin.id)}
            successCallback={closeModal}
            type="submit"
            variant="contained"
          >
            Confirm
          </StatefulButton>
          <SnackbarSimpleError
            open={submittingError}
            message={submittingError}
          />
        </div>
      </ContentModal>
    </>
  );
};

UseBinForm.propTypes = {
  bin: PropTypes.object.isRequired,
};

export default UseBinForm;
