import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Divider } from '@mui/material';
import { Expand, Compress } from '@mui/icons-material';

import styles from './styles';

const useStyles = makeStyles(styles);

// the trigger value is used to force something to re-expand if it had been collapsed after the previous click on expand (and vice versa for collapse)
const ExpandAndCollapsibleButtons = ({ setExpandAll, setTrigger, trigger }) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonContainer}>
      <Button
        onClick={() => {
          setExpandAll(true);
          setTrigger(!trigger);
        }}
        className={classes.button}
      >
        <div className={classes.iconContainer}>
          <Expand className={classes.icon} />
          EXPAND ALL
        </div>
      </Button>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Button
        onClick={() => {
          setExpandAll(false);
          setTrigger(!trigger);
        }}
        className={classes.button}
      >
        <div className={classes.iconContainer}>
          <Compress className={classes.icon} />
          COLLAPSE ALL
        </div>
      </Button>
    </div>
  );
};

ExpandAndCollapsibleButtons.propTypes = {
  setExpandAll: PropTypes.func.isRequired,
  setTrigger: PropTypes.func.isRequired,
  trigger: PropTypes.bool.isRequired,
};

export default ExpandAndCollapsibleButtons;
