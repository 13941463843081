import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

import {
  SLACKING,
  MIXING,
  COOKING,
  CHILLING,
  RTH_PORTIONING,
  ASSEMBLY,
} from 'lib/constants';
import { Breadcrumbs } from 'components/shared';

import { BreadcrumbContext } from '../BreadcrumbProvider';
import styles from './styles';

const useStyles = makeStyles(styles);

const ActivityNavigation = ({ history }) => {
  const classes = useStyles();
  const breadcrumbs = useContext(BreadcrumbContext);

  const rthPortioningEnabled = useSelector(
    (state) => state.session.experiments?.rth_portioning
  );

  if (breadcrumbs.length) {
    return (
      <div className={classes.breadcrumbContainer}>
        <Breadcrumbs backButton context={BreadcrumbContext} history={history} />
      </div>
    );
  }
  const isProduction =
    process.env.REACT_APP_API_URI == 'https://cs.homechef.com';

  return (
    <>
      <div className={classes.linkContainer}>
        <NavLink
          to={`/rth-production-submissions/${SLACKING}`}
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          {SLACKING.toUpperCase()}
        </NavLink>
        <NavLink
          to={`/rth-production-submissions/${MIXING}`}
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          {MIXING.toUpperCase()}
        </NavLink>
        <NavLink
          to={`/rth-production-submissions/${COOKING}`}
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          {COOKING.toUpperCase()}
        </NavLink>
        <NavLink
          to={`/rth-production-submissions/${CHILLING}`}
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          {CHILLING.toUpperCase()}
        </NavLink>
        {rthPortioningEnabled && (
          <NavLink
            to={`/rth-production-submissions/${RTH_PORTIONING}`}
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            {RTH_PORTIONING.toUpperCase()}
          </NavLink>
        )}
        <NavLink
          to={`/rth-production-submissions/${ASSEMBLY}`}
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          {ASSEMBLY.toUpperCase()}
        </NavLink>
        {!isProduction && (
          <NavLink
            to="/rth-production-submissions/binventory"
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            BINVENTORY
          </NavLink>
        )}
      </div>
    </>
  );
};

export default ActivityNavigation;
