import {
  GET_ACTIVITY_INGREDIENTS,
  GET_MEAL_PROGRESSES,
  GET_ACTIVITY_INGREDIENT_PROGRESSES,
  GET_ACTIVITY_INGREDIENT_PROGRESSES_SILENT,
  SUBMIT_MEAL_PROGRESS,
  SUBMIT_INGREDIENT_PROGRESS,
  PRINT_RTH_LABELS,
  SUBMIT_RTH_BINS,
  UPDATE_INGREDIENT_PROGRESS,
  SEARCH_RTH_BINS,
  RTH_BIN,
  GET_RTH_BINS,
} from 'lib/constants';
import { makeApiRequest } from 'lib/api';

import {
  destroyBin,
  getMealProgresses,
  getActivityIngredients,
  getActivityIngredientProgresses,
  postMealProgress,
  putMealProgress,
  postIngredientProgress,
  putIngredientProgress,
  printRthLabels,
  postBins,
  printRthBinLabels,
  putBin,
  queryBins,
  getBins,
} from './services';

export const fetchActivityIngredients = (params) => {
  return makeApiRequest({
    type: GET_ACTIVITY_INGREDIENTS,
    method: getActivityIngredients,
    params,
  });
};

export const fetchActivityIngredientProgresses = ({
  activity,
  ingredientId,
  mealIds,
}) => {
  return makeApiRequest({
    type: GET_ACTIVITY_INGREDIENT_PROGRESSES,
    method: getActivityIngredientProgresses,
    params: { activity, ingredientId, mealIds },
  });
};

export const fetchActivityIngredientProgressesSilent = ({
  activity,
  ingredientId,
  mealIds,
}) => {
  return makeApiRequest({
    type: GET_ACTIVITY_INGREDIENT_PROGRESSES_SILENT,
    method: getActivityIngredientProgresses,
    params: { activity, ingredientId, mealIds },
  });
};

export const fetchMealProgresses = (params) => {
  return makeApiRequest({
    type: GET_MEAL_PROGRESSES,
    method: getMealProgresses,
    params,
  });
};

export const submitMealProgress = (params) => {
  return makeApiRequest({
    type: SUBMIT_MEAL_PROGRESS,
    method: postMealProgress,
    params,
  });
};

export const updateMealProgress = (params) => {
  return makeApiRequest({
    type: SUBMIT_MEAL_PROGRESS,
    method: putMealProgress,
    params,
  });
};

export const submitIngredientProgress = (params) => {
  return makeApiRequest({
    type: SUBMIT_INGREDIENT_PROGRESS,
    method: postIngredientProgress,
    params,
  });
};

export const updateIngredientProgress = (params) => {
  return makeApiRequest({
    type: UPDATE_INGREDIENT_PROGRESS,
    method: putIngredientProgress,
    params,
  });
};

export const printLabels = (params) => {
  return makeApiRequest({
    type: PRINT_RTH_LABELS,
    method: printRthLabels,
    params,
  });
};

export const submitBins = (params) => {
  return makeApiRequest({
    type: SUBMIT_RTH_BINS,
    method: postBins,
    params,
  });
};

export const searchBins = (params) => {
  return makeApiRequest({
    type: SEARCH_RTH_BINS,
    method: queryBins,
    params,
  });
};

export const fetchBins = (params) => {
  return makeApiRequest({
    type: GET_RTH_BINS,
    method: getBins,
    params,
  });
};

export const updateBin = ({ binId, binParams }) => {
  return makeApiRequest({
    type: RTH_BIN,
    method: putBin,
    params: { binId, binParams },
  });
};

export const deleteBin = (params) => {
  return makeApiRequest({
    type: RTH_BIN,
    method: destroyBin,
    params,
  });
};

export const printBinLabels = (params) => {
  return makeApiRequest({
    type: PRINT_RTH_LABELS,
    method: printRthBinLabels,
    params,
  });
};
