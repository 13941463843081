import { GREY_100, ROBOTO } from 'lib/constants';

import { largeFontSize } from '../styles';

export default {
  actionCell: {
    verticalAlign: 'top',
    width: '25%',
  },
  collapseWrapper: {
    padding: '16px 20px 16px 20px',
  },
  detailsButton: {
    fontFamily: ROBOTO,
    fontWeight: 900,
  },
  detailsRow: {
    backgroundColor: GREY_100,
  },
  detailsRowHidden: {
    backgroundColor: GREY_100,
    display: 'none',
    transition: 'display 250ms allow-discrete',
  },
  title: {
    letterSpacing: '1px',
    fontFamily: ROBOTO,
    fontSize: largeFontSize,
    fontWeight: 900,
  },
  binCellContainer: {
    display: 'flex',
  },
};
