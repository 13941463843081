import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { useActivityIngredients } from 'lib/custom_hooks';
import { COOKING, COOKING_MACHINERY, COMPLETE_ACTION } from 'lib/constants';
import {
  submitIngredientProgress,
  updateIngredientProgress,
} from 'redux/productionProgress/rth/actions';
import { StatefulButton } from 'components/shared';

import styles from './styles';
import FormDrawer from '../formComponents/FormDrawer';
import SublotCard from '../../SublotCard';
import { PrinterContext } from '../../PrinterProvider';

const useStyles = makeStyles(styles);

const CookingForm = ({ open, selectedProgress, toggleOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getMealIdsByIngredientId } = useActivityIngredients();

  const [count, setCount] = useState('');
  const [machineryType, setMachineryType] = useState(null);
  const [machineryIdentifier, setMachineryIdentifier] = useState('');

  const {
    submitIngredientProgressFetching,
    submitIngredientProgressFailed,
  } = useSelector((state) => state.rthProductionProgress);

  const { setPrinterDialogOpen, setPrintSubmission } = useContext(
    PrinterContext
  );

  const buttonText = {
    DEFAULT: 'CONFIRM',
    SUCCESS: 'Success!',
    LOADING: 'SAVING...',
    ERROR: 'ERROR - Try Again',
  };

  const resetForm = () => {
    setCount('');
    setMachineryIdentifier('');
    setMachineryType(null);
  };

  const onToggleOpen = () => {
    resetForm();
    toggleOpen(false);
  };

  const mealIds = getMealIdsByIngredientId(selectedProgress.ingredientId);

  const onSubmit = () => {
    const data = {
      progressId: selectedProgress.id,
      activity: COOKING,
      count,
      machineryIdentifier,
      machineryType,
      mealIds,
    };
    dispatch(submitIngredientProgress(data));
  };

  const printLabels = (sublotId, submissionCount, isReprint = false) => {
    const printData = {
      progress: selectedProgress,
      submissionCount,
      sublotId,
      isReprint,
    };
    setPrintSubmission(printData);
    setPrinterDialogOpen(true);
  };

  const onUpdate = async (data) => {
    data.progressId = selectedProgress.id;
    data.mealIds = mealIds;

    const response = await dispatch(updateIngredientProgress(data));

    if (response.ok) {
      data.action === COMPLETE_ACTION && printLabels(data.sublotId, data.count);
      resetForm();
    }

    return response;
  };

  const renderSublots = () => {
    const hasOpenSublots = !isEmpty(selectedProgress.openSublots);
    const hasCompletedSublots = !isEmpty(selectedProgress.completedSublots);

    return (
      <>
        {!hasOpenSublots && !hasCompletedSublots && (
          <>
            <hr className={classes.formDivider} />
            <div className={classes.sublotDetails}>
              <div className={classes.sublotHeader}>
                No Complete or In-Progress Sublots
              </div>
            </div>
          </>
        )}
        {hasOpenSublots && (
          <>
            <hr className={classes.formDivider} />
            <div className={classes.sublotDetails}>
              <div className={classes.sublotHeader}>
                Update In-Progress Sublots and Print Labels
              </div>
              {selectedProgress?.openSublots?.map((sublot) => {
                return (
                  <div className={classes.sublot} key={sublot.id}>
                    <SublotCard
                      sublot={sublot}
                      onUpdate={onUpdate}
                      onPrint={printLabels}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
        {!isEmpty(selectedProgress?.completedSublots) && (
          <>
            <hr className={classes.formDivider} />
            <div className={classes.sublotDetails}>
              <div className={classes.sublotHeader}>
                Print Labels for Completed Sublots
              </div>
              {selectedProgress.completedSublots.map((sublot) => {
                return (
                  <div className={classes.sublot} key={sublot.id}>
                    <SublotCard
                      sublot={sublot}
                      onUpdate={onUpdate}
                      onPrint={printLabels}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <FormDrawer open={open} toggleOpen={onToggleOpen} drawerWidth="66%">
        <div className={classes.drawerContainer}>
          <div className={classes.drawerHeader}>
            {selectedProgress.ingredientName} - {selectedProgress.ingredientId}
          </div>
          <div className={classes.drawerSubHeader}>
            {selectedProgress.mealLetter} - {selectedProgress.mealTitle}
          </div>
          <div className={classes.formLabelText}>
            How many recipes are you cooking?
          </div>
          <TextField
            id="number-of-recipes"
            label="Number of Recipes"
            type="number"
            value={count}
            onChange={(e) => setCount(e.target.value)}
          />
          <FormControl>
            <div className={classes.formLabelText}>
              What type of equipment are you using?
            </div>
            <RadioGroup
              row
              name="controlled-radio-buttons-group"
              onChange={(e) => setMachineryType(e.target.value)}
            >
              {Object.keys(COOKING_MACHINERY).map((type) => (
                <FormControlLabel
                  control={<Radio checked={type === machineryType} />}
                  key={type}
                  label={type}
                  value={type}
                />
              ))}
            </RadioGroup>
            <div className={classes.formLabelText}>
              Which equipment number are you using?
            </div>
            <Select
              className={classes.dropdown}
              defaultValue=""
              displayEmpty
              data-testid="select-machinery-identifier"
              onChange={(e) => setMachineryIdentifier(e.target.value)}
              value={machineryIdentifier}
            >
              {machineryType &&
                COOKING_MACHINERY[machineryType].map((identifier) => (
                  <MenuItem value={identifier} key={identifier}>
                    {machineryType} {identifier}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <StatefulButton
            classes={{ root: classes.formSubmitButton }}
            type="submit"
            disabled={!count || !machineryType || !machineryIdentifier}
            loading={submitIngredientProgressFetching}
            failed={submitIngredientProgressFailed}
            buttonTextOptions={buttonText}
            onClick={onSubmit}
            successCallback={() => resetForm()}
          />
          {renderSublots()}
        </div>
      </FormDrawer>
    </>
  );
};

CookingForm.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedProgress: PropTypes.object.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default CookingForm;
