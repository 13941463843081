import React, { useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

import { SnackbarSimpleError, StatefulButton } from 'components/shared';
import useBins, { binValidationHelpers } from 'lib/custom_hooks/useBins';

import { PrinterContext } from '../../PrinterProvider';
import styles from './styles';

const useStyles = makeStyles(styles);

const DecantForm = ({ closeForm, sublotId }) => {
  const classes = useStyles();

  const [bins, setBins] = useState([]);
  const binIdCounterRef = useRef(1);

  const { fetchingError, fetching, onDecant } = useBins();
  const { setPrinterDialogOpen, setPrintSubmission } = useContext(
    PrinterContext
  );

  const resetForm = () => {
    setBins([]);
    closeForm();
  };

  const addBin = () => {
    setBins([...bins, { id: binIdCounterRef.current, weightInLbs: '' }]);
    binIdCounterRef.current += 1;
  };

  const removeBin = (id) => {
    setBins(bins.filter((bin) => bin.id !== id));
  };

  const handleInputChange = (id, value) => {
    const updatedFields = bins.map((bin) =>
      bin.id === id ? { ...bin, weightInLbs: value } : bin
    );
    setBins(updatedFields);
  };

  const handleSubmit = async () => {
    const response = await onDecant(bins, sublotId);

    if (response.ok) {
      setPrintSubmission({
        bins: response.bins,
        isDecant: true,
        sublotId,
      });
      setPrinterDialogOpen(true);
    }
  };

  const { invalidWeight, invalidWeightMessage } = binValidationHelpers;

  const invalidFormData = () => {
    if (bins.length === 0) return true;

    const emptyBins = bins.some((bin) => !bin.weightInLbs);
    const invalidBinWeights = bins.some((bin) =>
      invalidWeight(bin.weightInLbs)
    );

    return emptyBins || invalidBinWeights;
  };

  const buttonText = {
    DEFAULT: 'CONFIRM',
    SUCCESS: 'Success!',
    LOADING: 'SAVING...',
    ERROR: 'ERROR - Try Again',
  };

  return (
    <>
      <hr className={classes.formDivider} />
      <div className={classes.formContent}>
        <div className={classes.formHeader}>
          <div className={classes.formHeaderText}>Decant Sublot</div>
          <Button
            className={classes.cancelButton}
            color="error"
            onClick={resetForm}
            variant="text"
          >
            Cancel
          </Button>
        </div>
        <div className={classes.formBody}>
          <FormControl fullWidth variant="standard">
            {bins.map((bin, index) => (
              <div key={bin.id} className={classes.binRow}>
                <div className={classes.binFieldContainer}>
                  <div className={classes.binLabel}>Bin {index + 1}</div>
                  <TextField
                    className={classes.binInput}
                    error={invalidWeight(bin.weightInLbs)}
                    helperText={
                      invalidWeight(bin.weightInLbs) ? invalidWeightMessage : ''
                    }
                    inputProps={{ 'data-testid': `bin-input-${index}` }}
                    onChange={(e) => handleInputChange(bin.id, e.target.value)}
                    placeholder="Weight in lbs"
                    type="number"
                    value={bin.weightInLbs}
                  />
                </div>
                <IconButton
                  aria-label="remove-bin"
                  className={classes.trashIcon}
                  data-testid="remove-bin-button"
                  onClick={() => removeBin(bin.id)}
                  size="large"
                >
                  <Delete color="action" />
                </IconButton>
              </div>
            ))}
            <Button
              className={classes.addBinButton}
              color="primary"
              data-testid="add-bin-button"
              onClick={addBin}
              variant="contained"
            >
              <AddIcon className={classes.addIcon} />
              Add Bin
            </Button>
          </FormControl>
          <StatefulButton
            buttonTextOptions={buttonText}
            classes={{ root: classes.formSubmitButton }}
            disabled={invalidFormData()}
            failed={fetchingError}
            loading={fetching}
            onClick={handleSubmit}
            type="submit"
            successCallback={resetForm}
          />
          <SnackbarSimpleError open={fetchingError} message={fetchingError} />
        </div>
      </div>
    </>
  );
};

DecantForm.propTypes = {
  closeForm: PropTypes.func.isRequired,
};

export default DecantForm;
