import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const BinContext = createContext();

const BinProvider = ({ children }) => {
  const [bins, setBins] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [fetchingError, setFetchingError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submittingError, setSubmittingError] = useState(null);

  const resetState = () => {
    setBins([]);
    setFetching(false);
    setFetchingError(null);
    setSubmitting(false);
    setSubmittingError(null);
  };

  return (
    <BinContext.Provider
      value={{
        bins,
        fetching,
        fetchingError,
        submitting,
        submittingError,
        setBins,
        setFetching,
        setFetchingError,
        setSubmitting,
        setSubmittingError,
        resetState,
      }}
    >
      {children}
    </BinContext.Provider>
  );
};

BinProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BinProvider;
