import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { Route as ReactRouterDOMRoute, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setTopBarContent } from 'redux/topBar/actions';
import { getHoneyTagPrinters } from 'redux/printers/actions';
import {
  MIXING,
  COOKING,
  CHILLING,
  RTH_PORTIONING,
  SLACKING,
  HOME_BUTTON,
  ACCOUNT_DROPDOWN,
} from 'lib/constants';
import BinProvider from 'lib/providers/BinProvider';

import BreadcrumbProvider from './BreadcrumbProvider';
import PrinterProvider from './PrinterProvider';
import ActivityNavigation from './ActivityNavigation';
import ActivityIngredients from './ActivityIngredients';
import ActivityIngredientProgresses from './ActivityIngredientProgresses';
import MealAssembly from './MealAssembly';
import PrinterDialog from './PrinterDialog';
import Binventory from './Binventory';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const RthProductionSubmissions = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setTopBarContent({
        leftContent: HOME_BUTTON,
        rightContent: ACCOUNT_DROPDOWN,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(getHoneyTagPrinters());
  }, []);

  return (
    <BreadcrumbProvider>
      <PrinterProvider>
        <BinProvider>
          <Route
            path={['/rth-production-submissions']}
            render={() => <ActivityNavigation history={history} />}
          />
          <Route
            exact
            path={['/rth-production-submissions']}
            render={() => (
              <Redirect to="/rth-production-submissions/slacking" />
            )}
          />
          <Route
            exact
            path={[
              '/rth-production-submissions/slacking',
              '/rth-production-submissions/slacking/menu/:menuId',
            ]}
            render={() => (
              <ActivityIngredients activity={SLACKING} menusCount={5} />
            )}
          />
          <Route
            exact
            path={[
              '/rth-production-submissions/mixing',
              '/rth-production-submissions/mixing/menu/:menuId',
            ]}
            render={() => <ActivityIngredients activity={MIXING} />}
          />
          <Route
            exact
            path={[
              '/rth-production-submissions/cooking',
              '/rth-production-submissions/cooking/menu/:menuId',
            ]}
            render={() => <ActivityIngredients activity={COOKING} />}
          />
          <Route
            exact
            path={[
              '/rth-production-submissions/chilling',
              '/rth-production-submissions/chilling/menu/:menuId',
            ]}
            render={() => <ActivityIngredients activity={CHILLING} />}
          />
          <Route
            exact
            path={[
              '/rth-production-submissions/portioning',
              '/rth-production-submissions/portioning/menu/:menuId',
            ]}
            render={() => <ActivityIngredients activity={RTH_PORTIONING} />}
          />
          <Route
            path={[
              '/rth-production-submissions/:activity/menu/:menuId/ingredients/:ingredientId',
            ]}
            render={() => <ActivityIngredientProgresses />}
          />
          <Route
            exact
            path={[
              '/rth-production-submissions/assembly',
              '/rth-production-submissions/assembly/menu/:menuId',
            ]}
            render={() => <MealAssembly />}
          />
          <Route
            exact
            path={['/rth-production-submissions/binventory']}
            render={() => <Binventory />}
          />
          <PrinterDialog />
        </BinProvider>
      </PrinterProvider>
    </BreadcrumbProvider>
  );
};

RthProductionSubmissions.propTypes = {
  history: PropTypes.object.isRequired,
};

export default RthProductionSubmissions;
