import { HC_GREEN, TRANQUIL_BLUE, HOT_PINK_RED, ROBOTO } from 'lib/constants';
import { drawerStyling } from 'lib/styles';

const actionButton = {
  fontWeight: '800',
  fontFamily: ROBOTO,
  fontSize: '17px',
  margin: '4px 8px',
  padding: '4px 24px',
  textTransform: 'uppercase',
  width: '100%',
};

export default {
  buttons: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    justifySelf: 'end',
    marginLeft: 'auto',
  },
  bodySection: {
    ...drawerStyling.bodySection,
    height: 'calc(100vh - 145px)',
    margin: '0px 16px',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '8px',
  },
  closeIcon: {
    width: 20,
    height: 20,
  },
  useBinButton: {
    ...actionButton,
    borderColor: HC_GREEN,
    color: HC_GREEN,
  },
  drawer: {
    width: 600,
    fontFamily: ROBOTO,
  },
  formContent: {
    fontFamily: ROBOTO,
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '20px',
    marginTop: '24px',
  },
  formHeader: {
    fontFamily: ROBOTO,
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '32px',
  },
  formSubheader: {
    fontFamily: ROBOTO,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  formButtons: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '24px',
    spaceBetween: '8px',
  },
  reAllocateButton: {
    ...actionButton,
    borderColor: TRANQUIL_BLUE,
    color: TRANQUIL_BLUE,
  },
  topSection: {
    ...drawerStyling.topSection,
    margin: '24px 0px',
    justifyContent: 'end',
  },
  wasteButton: {
    ...actionButton,
    borderColor: HOT_PINK_RED,
    color: HOT_PINK_RED,
  },
  confirmButton: {
    marginLeft: '8px',
  },
};
