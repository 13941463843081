import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import WarningIcon from '@mui/icons-material/ReportProblemRounded';
import Grid from '@mui/material/Grid';
import flatMap from 'lodash/flatMap';
import classnames from 'classnames';

import { useTruckSummaryUpdater } from 'components/WarehouseReceivingApps/shared_hooks';
import { TruckIcon } from 'components/icons';
import { TabOutlineDiv, TimePickerWrapper } from 'components/shared';

import TruckSummaryButton from '../TruckSummaryButton';
import MemoDialog from '../MemoDialog';
import CriticalDefects from '../CriticalDefects';
import NetsuiteOrderLine from '../../NetsuiteOrderLine';

const EditTruckLine = ({
  classes,
  truck,
  ordersForTruck,
  onUpdateTruck,
  truckUpdatingFailed,
  onUpdateNetsuiteItem,
}) => {
  const { handleTruckSummaryChange } = useTruckSummaryUpdater({
    onUpdateTruck,
    truckUpdatingFailed,
    field: truck.dockOutTime,
    truckId: truck.id,
    backendFieldName: 'dock_out_time',
  });

  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const numberOfItemReceipts = useMemo(
    () => Object.keys(truck.itemReceiptStatuses).length,
    [truck.itemReceiptStatuses]
  );

  const numberOfNetsuiteOrderItems = useMemo(
    () => flatMap(ordersForTruck, 'netsuiteItems').length,
    [ordersForTruck]
  );

  return numberOfNetsuiteOrderItems > 0 ? (
    <Accordion expanded={expanded} classes={{ root: classes.truckExpander }}>
      <AccordionSummary
        className={classes.orderSummary}
        classes={{ content: classes.summaryContent }}
      >
        <div className={classes.truckContainer}>
          <TabOutlineDiv onClick={handleChange} role="button" tabIndex={0}>
            <Grid container className={classes.truckInfo}>
              <TruckIcon className={classes.truckIcon} />
              <Grid item xs={7}>
                <span className={classes.truckId}>Truck {`T${truck.id}`}</span>
                <span className={classes.separator}>|</span>{' '}
                {truck.vendorsArray.join(', ')}
              </Grid>

              <Grid item xs={4} className={classes.approvalCount}>
                <span
                  className={classnames({
                    [classes.approvedTruck]:
                      numberOfItemReceipts === numberOfNetsuiteOrderItems,
                  })}
                >
                  {numberOfItemReceipts !== numberOfNetsuiteOrderItems ? (
                    <WarningIcon className={classes.warningIcon} />
                  ) : null}{' '}
                  {`${numberOfItemReceipts}/${numberOfNetsuiteOrderItems} Approved`}
                </span>
              </Grid>
            </Grid>
          </TabOutlineDiv>
          <div className={classes.truckLine}>
            <TimePickerWrapper
              ampm={false}
              margin="normal"
              value={truck.dockOutTime || null}
              onAccept={handleTruckSummaryChange}
              renderInput={({ inputProps }) => {
                return (
                  <TruckSummaryButton field="Dock Out Time" {...inputProps} />
                );
              }}
            />
            <MemoDialog
              truckUpdatingFailed={truckUpdatingFailed}
              onUpdateTruck={onUpdateTruck}
              memo={truck.memo}
              truck={truck}
            />
            <CriticalDefects
              truckUpdatingFailed={truckUpdatingFailed}
              onUpdateTruck={onUpdateTruck}
              criticalDefects={truck.criticalDefects}
              truck={truck}
            />
          </div>
        </div>
      </AccordionSummary>
      <div data-testid="edit-truck-line-container">
        {ordersForTruck.map((netsuiteOrder, idx) => (
          <NetsuiteOrderLine
            key={netsuiteOrder.id}
            netsuiteOrder={netsuiteOrder}
            onUpdateNetsuiteItem={onUpdateNetsuiteItem}
            whichTab="summary"
            last={idx === ordersForTruck.length - 1}
            truck={truck}
          />
        ))}
      </div>
    </Accordion>
  ) : null;
};

EditTruckLine.propTypes = {
  classes: PropTypes.object.isRequired,
  truck: PropTypes.shape({
    vendor: PropTypes.string.isRequired,
    dockOutTime: PropTypes.string,
    id: PropTypes.number.isRequired,
  }).isRequired,
  onUpdateTruck: PropTypes.func.isRequired,
  truckUpdatingFailed: PropTypes.bool.isRequired,
  ordersForTruck: PropTypes.array,
  onUpdateNetsuiteItem: PropTypes.func,
};

EditTruckLine.defaultProps = {
  ordersForTruck: [],
  onUpdateNetsuiteItem: null,
};

export default EditTruckLine;
