import React, { Fragment, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MenuItem from '@mui/material/MenuItem';

import { datePickerReceivingStyles } from 'lib/dataTableTheme';
import { formatDateAsDDMMYY, formatDateHyphenated } from 'lib/utils';
import { WAREHOUSE_SHELF_LIFE_TYPES } from 'lib/constants';
import { useRotationTypeValidations } from 'lib/custom_hooks';

import { hasValues, useValidator } from '../FsqaReviewForms/formHelpers';
import styles from './styles';

const ProductDateCodeQuestion = ({
  classes,
  adjustedProductDate,
  adjustedProductDateType,
  productDateCodeCorrect,
  updateProductDateCodeCorrect,
  updateAdjustedProductDate,
  updateAdjustedProductDateType,
  initialProductDate,
  initialProductDateType,
  validationCallback,
  disabled,
}) => {
  const [adjustedPDateMatch, setAdjustedPDateMatch] = useState(false);

  useEffect(() => {
    const matchingProductDate =
      !disabled &&
      initialProductDate &&
      initialProductDateType &&
      adjustedProductDate &&
      adjustedProductDateType &&
      initialProductDate === formatDateHyphenated(adjustedProductDate) &&
      initialProductDateType === adjustedProductDateType;

    setAdjustedPDateMatch(matchingProductDate);
  }, [
    adjustedProductDate,
    adjustedProductDateType,
    disabled,
    initialProductDate,
    initialProductDateType,
  ]);

  useValidator(
    productDateCodeCorrect ||
      (hasValues([adjustedProductDate, adjustedProductDateType]) &&
        !adjustedPDateMatch),
    validationCallback
  );

  const {
    disableFutureDates,
    disablePastDates,
    onRotationTypeChange,
  } = useRotationTypeValidations(
    adjustedProductDate,
    adjustedProductDateType,
    updateAdjustedProductDate,
    updateAdjustedProductDateType
  );

  const onYesClick = () => {
    updateProductDateCodeCorrect(true);
    updateAdjustedProductDate(null);
    updateAdjustedProductDateType(null);
  };

  const formattedProductDateCode = useMemo(() => {
    if (initialProductDate) {
      return `${formatDateAsDDMMYY(
        initialProductDate
      )}: ${initialProductDateType}`;
    } else {
      return null;
    }
  }, [initialProductDate, initialProductDateType]);

  return (
    <Fragment>
      <span className={classes.infoLabel}>
        {'IS THE PRODUCT DATE CODE '}
        <strong>{`${formattedProductDateCode || 'BLANK'}`}</strong>
        {'?'}
      </span>
      <div
        className={classNames(classes.infoValue, {
          [classes.disabledField]: disabled,
        })}
      >
        <Button
          className={classNames(classes.button, {
            [classes.selectedYes]: productDateCodeCorrect === true,
          })}
          variant="outlined"
          color="neutral"
          onClick={onYesClick}
        >
          Yes
        </Button>
        <Button
          className={classNames(classes.button, {
            [classes.selectedNo]: productDateCodeCorrect === false,
          })}
          variant="outlined"
          color="neutral"
          onClick={() => updateProductDateCodeCorrect(false)}
        >
          No
        </Button>

        {productDateCodeCorrect === false && (
          <Fragment>
            <TextField
              required={productDateCodeCorrect === false}
              label="Rotation Type"
              value={adjustedProductDateType}
              select
              onChange={(e) => onRotationTypeChange(e)}
              variant="outlined"
              margin="normal"
              className={classes.textField}
              data-testid="product-date-type"
              InputLabelProps={{ required: false, shrink: true }}
            >
              {WAREHOUSE_SHELF_LIFE_TYPES.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={datePickerReceivingStyles}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    id="date"
                    type="string"
                    format="MM/dd/YYY"
                    label="Product Date"
                    value={adjustedProductDate || null}
                    emptyLabel=""
                    onChange={(e) => updateAdjustedProductDate(e)}
                    disabled={!adjustedProductDateType}
                    disableFuture={disableFutureDates}
                    disablePast={disablePastDates}
                    renderInput={(params) => (
                      <TextField variant="outlined" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </Fragment>
        )}
        {adjustedPDateMatch && (
          <div className={classes.errorText}>
            {`Adjusted product date code appears to match the above product date code, ${formattedProductDateCode}. Please select "Yes" or enter different product date code.`}
          </div>
        )}
      </div>
    </Fragment>
  );
};

ProductDateCodeQuestion.propTypes = {
  classes: PropTypes.object.isRequired,
  initialProductDate: PropTypes.string,
  initialProductDateType: PropTypes.string,
  productDateCodeCorrect: PropTypes.bool,
  adjustedProductDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  adjustedProductDateType: PropTypes.string,
  updateAdjustedProductDate: PropTypes.func.isRequired,
  updateAdjustedProductDateType: PropTypes.func.isRequired,
  updateProductDateCodeCorrect: PropTypes.func.isRequired,
  validationCallback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ProductDateCodeQuestion.defaultProps = {
  productDateCodeCorrect: null,
  adjustedProductDate: '',
  adjustedProductDateType: '',
  initialProductDate: '',
  initialProductDateType: '',
  disabled: false,
};

export default withStyles(styles)(ProductDateCodeQuestion);
