import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import ExpandAndCollapsibleButtons from 'components/RthProgressTracker/ExpandAndCollapsibleButtons';
import useBins from 'lib/custom_hooks/useBins';
import { Loading } from 'components/shared';

import styles from './styles';
import BinRow from './BinRow';

const useStyles = makeStyles(styles);

// SORTING CONSTANTS
// key names on bin object that we'll sort off of
const EXPIRES_AT = 'expiresAt';
const INGREDIENT_NAME = 'ingredientName';
const MEAL_NAME = 'mealName';
// mapping of key names to select display names
const sortOptions = [
  { value: EXPIRES_AT, displayName: 'FEFO' },
  { value: INGREDIENT_NAME, displayName: 'Ingredient Name' },
  { value: MEAL_NAME, displayName: 'Meal Name' },
];

export const Binventory = () => {
  const classes = useStyles();
  const { bins, onFetchBins, fetching, fetchingError } = useBins();
  const [sortBy, setSortBy] = useState(EXPIRES_AT);
  const [sortedBins, setSortedBins] = useState([]);
  const [expandAll, setExpandAll] = useState(false);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    onFetchBins();
  }, []);

  useEffect(() => {
    if (sortBy) {
      const sorted = [...bins].sort((a, b) =>
        sortBy === EXPIRES_AT
          ? new Date(a.expiresAt) - new Date(b.expiresAt)
          : a[sortBy].localeCompare(b[sortBy])
      );

      setSortedBins(sorted);
    }
  }, [sortBy, bins]);

  const renderControlsBar = () => (
    <div className={classes.controlsBar}>
      <div>
        <div className={classes.selectPreText}>SORT BY:</div>
        <Select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          size="small"
          data-testid="sort-select"
        >
          {sortOptions.map(({ value, displayName }) => (
            <MenuItem key={value} value={value}>
              {displayName}
            </MenuItem>
          ))}
        </Select>
      </div>
      <ExpandAndCollapsibleButtons
        setExpandAll={setExpandAll}
        trigger={trigger}
        setTrigger={setTrigger}
      />
    </div>
  );

  const renderTable = () => {
    return (
      <TableContainer className={classes.container} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableTitle} colSpan={2}>
                BIN ID
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedBins.map((bin) => (
              <BinRow
                key={bin.id}
                bin={bin}
                expandAll={expandAll}
                trigger={trigger}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderPage = () => {
    if (fetching) {
      return <Loading fullScreen />;
    } else if (fetchingError) {
      return <div>Error occurred while fetching bins</div>;
    } else if (isEmpty(bins)) {
      return <div>No available bins</div>;
    } else {
      return (
        <>
          {renderControlsBar()}
          {renderTable()}
        </>
      );
    }
  };

  return <div className={classes.wrapper}>{renderPage()}</div>;
};
