import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

import { ellipseText, numberWithCommas } from 'lib/utils';
import { useControllableProp, usePrevious } from 'lib/custom_hooks';

const formatValue = (value, units) => {
  return `${numberWithCommas(value)}${
    units ? ` (${ellipseText(units, 15)})` : ''
  }`;
};

// This component can be controlled or uncontrolled. See description in useControllableProp
const SubtractionInputs = ({
  classes,
  initialValue,
  subtractingValue,
  updateSubtractingValue,
  updateFinalValue,
  units,
  hasError,
  autoFocus,
  text,
  inputType,
}) => {
  const [
    currentSubractionValue,
    setCurrentSubtractionValue,
  ] = useControllableProp({
    value: subtractingValue,
    updater: updateSubtractingValue,
    defaultValue: 0,
  });

  const difference = useMemo(
    () => Number(initialValue) - Number(currentSubractionValue),
    [initialValue, currentSubractionValue]
  );
  const prevDifference = usePrevious(difference);

  useEffect(() => {
    if (updateFinalValue && prevDifference && prevDifference !== difference) {
      updateFinalValue(difference);
    }
  }, [difference, updateFinalValue, prevDifference]);

  const formattedInitialValue = formatValue(initialValue, units);
  const formattedDifference = formatValue(difference, units);

  // console.log(formattedInitialValue, formattedDifference);

  return (
    <div className={classes.root}>
      <label className={classes.textFieldWrapper} htmlFor="initialValue">
        {text.initialValueLabel}
        <TextField
          value={formattedInitialValue}
          variant="outlined"
          InputProps={{
            classes: { notchedOutline: classes.noBorder },
          }}
          className={classes.textFieldDisabled}
          disabled
          type="text"
          id="initialValue"
          data-testid="initial-value"
        />
      </label>
      <label className={classes.textFieldWrapper} htmlFor="subtractingValue">
        {text.subtractingValueLabel}
        <TextField
          value={currentSubractionValue}
          onChange={(e) => setCurrentSubtractionValue(e.target.value)}
          onFocus={(e) => e.target.select()}
          onSelect={(e) => e.preventDefault()}
          variant="outlined"
          error={hasError}
          className={classes.textField}
          type={inputType}
          inputProps={{
            min: 0,
            step: 1,
            'data-testid': 'subtracting-value-input',
          }}
          id="subtractingValue"
          data-testid="subtracting-value"
          autoFocus={autoFocus}
        />
      </label>
      <label className={classes.textFieldWrapper} htmlFor="resultValue">
        {text.resultLabel}
        <TextField
          value={formattedDifference}
          variant="outlined"
          InputProps={{
            classes: { notchedOutline: classes.noBorder },
          }}
          className={classes.textFieldDisabled}
          disabled
          type="text"
          id="resultValue"
          data-testid="result-value"
        />
      </label>
      {hasError && <div className={classes.errorText}>{text.errorText}</div>}
    </div>
  );
};

SubtractionInputs.propTypes = {
  classes: PropTypes.object.isRequired,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  subtractingValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  updateSubtractingValue: PropTypes.func,
  updateFinalValue: PropTypes.func,
  units: PropTypes.string,
  hasError: PropTypes.bool,
  text: PropTypes.shape({
    initialValueLabel: PropTypes.string,
    subtractingValueLabel: PropTypes.string,
    resultLabel: PropTypes.string,
    errorText: PropTypes.string,
  }),
  autoFocus: PropTypes.bool,
  inputType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SubtractionInputs.defaultProps = {
  hasError: false,
  updateFinalValue: undefined,
  subtractingValue: undefined,
  updateSubtractingValue: undefined,
  units: '',
  text: {},
  autoFocus: false,
  inputType: 'number',
};

export default SubtractionInputs;
