import {
  GREY_200,
  GREY_600,
  ERROR_RED,
  ROBOTO,
  WARNING_DARK,
  HC_GREEN,
  BLACK,
} from 'lib/constants';

const primaryInfo = {
  fontSize: '24px',
  lineHeight: '133%',
};

const secondaryInfo = {
  fontSize: '14px',
  lineHeight: '143%',
  letterSpacing: '0.15px',
  color: GREY_600,
};

export default {
  card: {
    border: `1px solid ${GREY_200}`,
    borderRadius: '6px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  primaryInfo,
  secondaryInfo,
  secondaryInfoBold: {
    ...secondaryInfo,
    fontWeight: 'bold',
    color: BLACK,
  },
  binContainer: {
    ...primaryInfo,
    margin: '24px 8px 0 0',
  },
  carryoverInfo: {
    ...secondaryInfo,
    color: HC_GREEN,
  },
  nilEquipment: {
    color: ERROR_RED,
    textTransform: 'uppercase',
  },
  onHoldWarning: {
    alignItems: 'center',
    color: WARNING_DARK,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: ROBOTO,
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '266%',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
};
