import { BLACK, GREY_100, GREY_200, GREY_800, ROBOTO } from 'lib/constants';

import { smallFontSize, medFontSize } from '../../styles';

export const subInfo = {
  color: GREY_800,
  display: 'inline-block',
  fontFamily: ROBOTO,
  fontSize: smallFontSize,
  fontWeight: 400,
  lineHeight: '20px',
};

export default {
  container: {
    backgroundColor: GREY_100,
    fontFamily: ROBOTO,
    fontSize: medFontSize,
    width: '100%',
  },
  itemContainer: {
    borderBottom: `1px solid ${GREY_200}`,
    fontSize: smallFontSize,
    padding: '10px 0px',
    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  mealInfo: {
    ...subInfo,
    display: 'block',
  },
  subInfo,
  subTitle: {
    color: BLACK,
    display: 'inline-block',
    fontFamily: ROBOTO,
    fontSize: smallFontSize,
    fontWeight: 900,
    lineHight: '20px',
    minWidth: '100px',
    paddingRight: '8px',
    verticalAlign: 'top',
  },
  title: {
    fontFamily: ROBOTO,
    fontSize: medFontSize,
    fontWeight: 900,
    paddingBottom: '10px',
    display: 'flex',
    textTransform: 'uppercase',
  },
  titleIngredientName: {
    paddingLeft: '8px',
  },
};
