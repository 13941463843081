import React, { useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import TableSortLabel from '@mui/material/TableSortLabel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CheckCircleOutline from '@mui/icons-material/CheckOutlined';

import styles from './styles';

export const CollapsibleArea = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const CollapseClickArea = cloneElement(children[0], {
    isOpen,
    setIsOpen,
  });
  const CollapseContent = cloneElement(children[1], { isOpen });

  return (
    <React.Fragment>
      {CollapseClickArea}
      {CollapseContent}
    </React.Fragment>
  );
};

export const StatusIcon = withStyles(styles)(({ classes, iconType }) => {
  const iconMap = {
    filledCheck: <CheckCircle classes={{ root: classes.circleCheck }} />,
    outlineCheck: (
      <CheckCircleOutline classes={{ root: classes.circleCheck }} />
    ),
    circle: <div className={classes.todoIcon} />,
  };

  return iconMap[iconType];
});

export const TableSort = withStyles(styles)(
  ({ classes, sortStatus, id, onSortClick, children }) => (
    <TableSortLabel
      data-testid="table-sort-label"
      active
      direction={sortStatus.sortColumn === id ? sortStatus.sortBy : 'asc'}
      onClick={() => onSortClick(id)}
      classes={{
        root: classes.sortLabel,
        icon:
          sortStatus.sortColumn === id
            ? classes.sortLabel
            : classes.sortLabelInactive,
      }}
    >
      {children}
    </TableSortLabel>
  )
);

CollapsibleArea.propTypes = { children: PropTypes.node.isRequired };

StatusIcon.defaultProps = {
  iconType: 'circle',
};

StatusIcon.propTypes = {
  iconType: PropTypes.oneOf(['filledCheck', 'outlineCheck', 'circle']),
};

TableSort.propTypes = {
  sortStatus: PropTypes.shape({
    sortColumn: PropTypes.string,
    sortBy: PropTypes.string,
  }).isRequired,
  onSortClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
