import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import isEmpty from 'lodash/isEmpty';

import BinFormDrawer from '../BinFormDrawer';
import styles from '../styles';

const useStyles = makeStyles(styles);

export const ReallocateForm = ({ bin }) => {
  const classes = useStyles();
  const [open, toggleOpen] = useState(false);

  return (
    <>
      <Button
        className={classes.reAllocateButton}
        variant="outlined"
        onClick={() => toggleOpen(true)}
        disabled={isEmpty(bin.relatedMealsForIngredientForShipweek)}
      >
        Reallocate
      </Button>
      <BinFormDrawer
        open={open}
        toggleOpen={toggleOpen}
        data-testid={`reallocate-form-bin-${bin.id}`}
      >
        <Typography className={classes.formHeader}>
          Allocate to New Meal
        </Typography>
        <Typography className={classes.formSubheader}>
          Bin {bin.id} - {bin.ingredientName}
        </Typography>
      </BinFormDrawer>
    </>
  );
};

ReallocateForm.propTypes = {
  bin: PropTypes.object.isRequired,
};

export default ReallocateForm;
