import React from 'react';
import times from 'lodash/times';
import makeStyles from '@mui/styles/makeStyles';
import sample from 'lodash/sample';

import styles from './styles';

const useStyles = makeStyles(styles);

const Autumn = () => {
  const classes = useStyles();

  const leaves = [
    classes.yellowLeaf,
    classes.brownLeaf,
    classes.orangeLeaf,
    classes.redLeaf,
  ];
  return (
    <div>
      {times(60, (i) => {
        //for each random leaf, run this iteration 60 times
        //when it is run, display a random element from the collection
        return <div key={i} className={sample(leaves)} />;
      })}
      <div className={classes.pumpkinWagon} />
      <div className={classes.wheatField} />
      <div className={classes.turkey} />
      <div className={classes.pumpkinSpiceLatte} />
      <div className={classes.willie} />
      <div className={classes.autumnTree} />
    </div>
  );
};

export default Autumn;
